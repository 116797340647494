<template>
  <Page title="Browse Farms">
    <v-form @submit.prevent="">
      <v-row dense>
        <v-col cols="12" :sm="hasMultipleClients ? 4 : 8">
          <v-text-field
            v-model="options.params.title"
            label="Search"
            clearable
          ></v-text-field>
        </v-col>
        <v-col v-if="hasMultipleClients" cols="12" sm="4">
          <v-select
            :items="clients"
            item-value="id"
            item-text="title_with_id"
            v-model="options.params.client_id"
            label="Client"
            clearable
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-radio-group v-model="show">
            <v-row dense>
              <v-col>
                <v-radio label="Show Active" value="active"></v-radio>
              </v-col>
              <v-col>
                <v-radio label="Show All" value="all"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table v-bind="customAttrs" :options.sync="options">
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editItem(item)" v-bind="attrs" v-on="on"
              >mdi-pencil</v-icon
            >
          </template>
          <span>Edit Farm</span>
        </v-tooltip>
        <span v-if="item.is_active">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Deactivate Farm</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-check</v-icon
              >
            </template>
            <span>Activate Farm</span>
          </v-tooltip>
        </span>
        &nbsp;<router-link :to="sourcesUrl(item)">Manage Sources</router-link>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check</v-icon>
        {{ item.is_active ? "Active" : "Inactive" }}
      </template>
    </v-data-table>
    <v-btn color="primary" @click="addFarm">Add Farm</v-btn>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import { mapActions, mapGetters, mapState } from "vuex";
import dataTablePagination from "@/mixins/dataTablePagination";
export default {
  name: "FarmsBrowse",
  components: { Page },
  mixins: [dataTablePagination],
  data() {
    return {
      loading: true,
      search: "",
      show: "active",
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "title" },
        { text: "Number", value: "number" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      options: {
        params: {
          title: "",
          client_id: ""
        }
      }
    };
  },
  computed: {
    ...mapGetters("clients", ["hasMultipleClients"]),
    ...mapState("clients", { clients: "items" }),
    customAttrs() {
      return {
        ...this.defaultAttrs,
        items: this.shownFarms
      };
    },
    shownFarms() {
      return this.show === "active"
        ? this.items.filter(farm => farm.is_active)
        : this.items;
    }
  },
  methods: {
    ...mapActions("farms", ["getPaginated", "editFarm"]),
    toggleActivation(item) {
      this.editFarm({ id: item.id, is_active: !item.is_active }).then(() => {
        this.getPaginatedProxy();
      });
    },
    editItem(item) {
      this.$router.push(`/farms/edit/${item.id}`);
    },
    addFarm() {
      this.$router.push("/farms/add");
    },
    sourcesUrl(item) {
      return `/farms/${item.id}/sources`;
    }
  }
};
</script>

<style scoped></style>
